'use client';
import * as React from 'react';
import { FC } from 'react';

import HomeIcon from '@mui/icons-material/Home';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { Locale } from '@/i18n.config';

import { buildUrl } from '../services/url.service';

interface Props {
  lang: Locale;
  path?: string[];
  navigation: Record<string, string>;
}

export const Breadcrumbs: FC<Props> = ({ lang, path = [], navigation }) => (
  <MuiBreadcrumbs aria-label="breadcrumb" component="nav" sx={{ mt: 1 }}>
    <Link color="inherit" href="/" lang={lang} sx={{ alignItems: 'center', display: 'flex' }} underline="hover">
      <HomeIcon fontSize="inherit" sx={{ mr: 0.5 }} />
      {navigation.home}
    </Link>

    {path.map((item, index) => {
      const last = index === path.length - 1;

      return (
        <Link
          key={item}
          color={last ? 'text.primary' : 'inherit'}
          href={buildUrl(path, index + 1)}
          lang={lang}
          sx={{ alignItems: 'center', display: 'flex' }}
          underline="hover"
        >
          {navigation[item]}
        </Link>
      );
    })}
  </MuiBreadcrumbs>
);
